export enum ErrorBusiness {
  'SELF-ADJUST' = 'SELF-ADJUST',
  'SELF-BOOKING' = 'SELF-BOOKING',
  'SELF-LEAVE' = 'SELF-LEAVE',
}

export type ErrorData = {
  business: ErrorBusiness;
  errorMsg: string;
  errorType: 'OTHER' | string;
  message?: string;
};
