import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Router from 'next/router';
import qs from 'query-string';
import { REFRESH_TOKEN, TOKEN } from '@/utils/constants';
import CacheStorage from '@/utils/cacheStorage';
import { Toast } from '@/components';
import { API_HOST } from '@/utils/env';
import { removeTokenInfo, setTokenInfo } from '@/utils/helpers';
import { LoginRes } from './types/login';
import { finishTransaction, startTransaction } from '@/utils/sentryUtil';
import { getAPIBaseUrl } from '@/utils/url';
import { selfSeriesErrorHandler } from '@/pageComponents/SelfSeries/utils/apiResponseHandler';

declare module 'axios' {
  interface AxiosRequestConfig {
    skipErrorHandler?: boolean;
    skipTransformResponse?: boolean;
    skipErrorAccess?: boolean;
  }
}

const client = axios.create({
  timeout: 30000,
  baseURL: getAPIBaseUrl(API_HOST),
});

export const selfSeriesClient = axios.create({ timeout: 30000, baseURL: getAPIBaseUrl(API_HOST) });

const requestHandler = (requestConfig: AxiosRequestConfig) => {
  startTransaction(requestConfig);
  const { router } = Router;
  const { sign } = qs.parse(globalThis.location?.search || '');
  const language = router?.locale ?? 'zh';
  /* eslint-disable no-param-reassign */
  requestConfig.headers.Authorization = CacheStorage.getItem(TOKEN) ? `Bearer ${CacheStorage.getItem(TOKEN)}` : '';
  // eslint-disable-next-line no-nested-ternary
  requestConfig.headers['Accept-Language'] = language ? (language === 'zh' ? 'zh-CN' : language) : 'zh-CN';
  requestConfig.headers.platform = 'web';
  if (sign) {
    requestConfig.headers.sign = encodeURIComponent(sign as string);
  }
  return requestConfig;
};

const responseHandler = (res: AxiosResponse) => {
  finishTransaction(res);
  const { config } = res || {};

  if (config.skipTransformResponse) {
    return res;
  }

  return res.data;
};

/**
 *
 * @param err
 * @param noHitProcess
 * 自主系列项目的错误类型处理 兼容
 * 默认规则没有命中的话，可以走noHitProcess来单独处理错误类型
 * 后续的错误处理请考虑是不是默认都有的，不是的话请写在noHitProcess里处理
 */
const errorHandler = (err: any, noHitProcess?: (err: AxiosError) => any) => {
  if (!err.response) throw err;

  const { status, config, data = {} } = err.response;
  const { errors, message } = data;
  const errMsg = (errors && errors[Object.keys(errors)[0]]) || message;

  if (+status === 433) {
    Router.replace('/notice/now');
    // throw new Error(`${status}  维护中`);
  }

  if (+status === 401 && !config?.skipErrorAccess) {
    removeTokenInfo(null, false);
    // throw new Error(`${status}  Not Authorization`);
  }

  if (+status === 406 || +status === 403) {
    const url = +status === 406 ? '/api/authorized/refresh_token' : '/api/authorized/refresh_token_V2';
    const data =
      +status === 406
        ? undefined
        : {
            refreshToken: CacheStorage.getItem(REFRESH_TOKEN),
          };
    client.post<LoginRes, LoginRes>(url, data).then((res) => {
      const { token, refreshToken = '' } = res?.content || {};
      setTokenInfo(token, refreshToken);
      window.location.reload();
      // throw new Error(`${status}  Refresh token`);
    });
    return;
  }

  if (+status === 422 && !config?.skipErrorHandler) {
    const clearError = errMsg || '未知错误';
    Toast.warning(clearError);
    // throw new Error(`[Server Response Error]: ${clearError}`);
  }

  if (config.skipErrorHandler) {
    throw err;
  }

  if (noHitProcess) {
    noHitProcess(err);
    throw err;
  }
  if (typeof data === 'string' || errMsg) {
    Toast.error(errMsg);
  }
  throw err;
};

client.interceptors.request.use(requestHandler);
client.interceptors.response.use(responseHandler, (err) => errorHandler(err));

selfSeriesClient.interceptors.request.use(requestHandler);
selfSeriesClient.interceptors.response.use(responseHandler, (err) => errorHandler(err, selfSeriesErrorHandler));

export default client;
