import { i18n } from 'next-i18next';

export const SELFSERVICE_RESCHEDULING = 'selfServiceReScheduling';
export const SELF_BOOKING = 'selfBooking';

i18n?.loadNamespaces([SELFSERVICE_RESCHEDULING, SELF_BOOKING]);

const spaceT = (nameSpace: string, key: string) => i18n?.t(`${nameSpace}:${key}`);

export const getSpaceT = (nameSpace: string) => {
  return (key: string) => spaceT(nameSpace, key) ?? '';
};
