// eslint-disable-next-line import/no-cycle
import CacheStorage from '@/utils/cacheStorage';
import * as process from 'process';

export const TOKEN = 'token';
export const SIGN = 'sign';
export const REFRESH_TOKEN = 'refresh_token';
export const LAST_REFRESH_TIME = 'last_refresh_time';
export const DEFAULT_THROTTLE_TIME = 300;
export const CURRENT_LANGUAGE = 'NEXT_LOCALE';
export const WECHAT_QR_HOST = 'https://open.weixin.qq.com/connect/qrconnect';
export const POST_MESSAGE_ID = 'd3Vrb25nbXNn';
export const PRIVACY_AGREEMENT_URL = 'https://www.wukongsch.com/legacy/policy.html';
export const OFFICIAL_WEBSITE_URL = `https://www.wukongsch.com/${CacheStorage.getItem(CURRENT_LANGUAGE)}`;
export const SIMP_OFFICIAL_WEBSITE_URL = `https://www.wukongsch.com/${CacheStorage.getItem(CURRENT_LANGUAGE)}`;
export const OFFICIAL_WEBSITE_EDU_URL = `https://www-intl.wukongedu.net/${CacheStorage.getItem(CURRENT_LANGUAGE)}`;
export const SIMP_OFFICIAL_WEBSITE_EDU_URL = `https://www-intl.wukongedu.net`;
export const OFFICIAL_WEBSITE_URL_TEST = `https://www-new-test.wukongedu.net/${CacheStorage.getItem(CURRENT_LANGUAGE)}`;
export const SIMP_OFFICIAL_WEBSITE_URL_TEST = process.env.NEXT_PUBLIC_OFFICIAL_WEBSITE_URL;
export const STU_WEBSITE_URL = `https://student.wukongedu.net`;
export const STU_WEBSITE_URL_TEST = `https://student-test.wukongedu.net`;
export const USER_AGREEMENT_URL = 'https://student.wukongedu.net/agreement';
export const TOKEN_VALID_DAY = 30;
export const EMAIL_LENGTH = 50;
export const NEED_REFRESH_TOKEN_DAY = 15;
export const USER_EMAIL = 'user_email';
export const CLASS_IN_URL = 'https://www.eeo.cn/client/invoke/index.html';
export const EDU_URL = '.wukongedu.net';
export const SCH_URL = '.wukongsch.com';
export const QR_CODE_WHATSAPP = 'https://diversion.salesmartly.com/split/whatsapp/e8hf3u';
export const DOWNLOAD_APP_CENTER_PAGE = 'https://wukongedu.net/wp/launch-app';
// 企业微信默认
export const QR_CODE_WX = 'https://cdnwukong.com/images/form/imgs/public-form-qrcode.png';
// 正则邮箱
export const REGEXP_EMAIL = /^[^@]{1,}@[^@]{1,}$/;
export const REGEXP_PHONE = /^\d+$/;
export const REGEXP_PASSWORD = /^\S*(?=\S{8,})(?=\S*\d)(?=\S*[a-z])\S*$/;
// 邮箱验证码 4-6位数字
export const REGEXP_EMAIL_CODE = /^[0-9]{4,6}$/;
export const ONLINE = 'online';
export const OFFLINE = 'offline';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_WITH_POINT = 'YYYY.MM.DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const TIME_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT_WITHOUT_SEC = 'HH:mm';

export const SELECT_DATE = 'select_date';

export const WEEKS_FORMAT = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];

export const WEEKS_FORMAT_EN = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const LanguageList = [
  {
    label: '中文',
    value: 'zh',
  },
  {
    label: 'ENG',
    value: 'en',
  },
];

export const NewLanguageList = [
  {
    label: '中文',
    value: 'zh',
  },
  {
    label: 'English',
    value: 'en',
  },
];

export const sourceMap = new Map([
  ['official_website', '官网登录留资'],
  ['landing', '可配置落地页'],
  ['email', '约课成功邮件'],
  ['official_website_landing', '官网未登留资'],
]);

export const GTM_DEFAULT_ARGUS = {
  id: 'GTM-5GTQ77B',
  dataLayerName: 'student_center',
};

export const GTM_CHINESE_BOOKING_ARGUS = {
  id: 'GTM-TCG48F6',
  dataLayerName: 'chinese_booking',
};

export const GTM_MATH_BOOKING_ARGUS = {
  id: 'GTM-TGGBPQ6',
  dataLayerName: 'MATH_booking',
};

export const USER_ID = 'userId';

export const FACEBOOK_APP_ID = '280182753983648';

export const STUDENT_TIMEZONES = 'STUDENT_TIMEZONES';

export const LESSON_TAG = 'LESSON_TAG';

export const LESSON_LIST_TAG = 'LESSON_LIST_TAG';

export const LESSON_LIST_HEIGHT = 'LESSON_LIST_HEIGHT';

export const LESSON_LIST_ACTIVE_SUBJECT = 'LESSON_LIST_ACTIVE_SUBJECT';

export const LESSON_LIST_PAGE_NUM = 'LESSON_LIST_PAGE_NUM';

// 移动端登陆保存用户输入的邮件名
export const LOGIN_EMAIL = 'login_email';

export const ACCOUNT_ID = 'accountId';

export const NEED_SET_PWD_USERS = 'NEED_SET_PWD_USERS';

export const LEAD_FLAG = 'leadFlag';

export const COOKIE_FLAG = 'COOKIE_FLAG';
export const CLASS_GUIDE_FLAG = 'classGuideFlag';

export const TEST_DEVICE_RESULT = 'TEST_DEVICE_RESULT';

export const SUBJECT_MAP = new Map([
  ['MATH', '数学'],
  ['ENGLISH', '英语'],
  ['CHINESE', '中文'],
]);

export enum Subject {
  chinese = 'Chinese',
  english = 'English',
  math = 'Math',
}

export const CHINESE = 'zh';
export const ENGLISH = 'en';

// source相关(跟埋点有关)
// 学员中心-上课须知source
export const STUDENT_WEB_INSTRUCTIONS = 'STUDENT_WEB_INSTRUCTIONS';
export const STUDENT_MOBILE_INSTRUCTIONS = 'STUDENT_MOBILE_INSTRUCTIONS';
// app内
export const APP = 'APP';
// 学员中心首页
export const STUDENT_WEB = 'STUDENT_WEB';

// 上课须知埋点事件名
export const COURSE_GUIDANCE_SCAN_WHATSAPP = 'U_CourseGuidance_ScanWhatsApp';
export const COURSE_GUIDANCE_ADD_WHATSAPP = 'U_CourseGuidance_AddWhatsApp';
export const COURSE_GUIDANCE_ADD_WECHAT = 'U_CourseGuidance_AddWeChat';
export const STUDENT_MOBILE = 'STUDENT_MOBILE';
// 右下角头像, 引导学员加
export const ACADEMIC_ADVISOR_POP_ADD_WHATSAPP = 'O_AcademicAdvisorPop_AddWhatsApp';
export const ACADEMIC_ADVISOR_POP_ADD_WECHAT = 'O_AcademicAdvisorPop_AddWeChat';
export const ACADEMIC_ADVISOR_POP_HOVER = 'O_AcademicAdvisorPop_Hover';
export const ACADEMIC_ADVISOR_POP_CLICK = 'O_AcademicAdvisorPop_Click';
export const ACADEMIC_ADVISOR_POP = 'O_AcademicAdvisorPop';
export const ACADEMIC_ADVISOR_POP_SCAN_WHATSAPP = 'O_AcademicAdvisorPop_ScanWhatsApp';

export const CURRENT_STUDENT_CODE = 'curStudentCode';
export const CURRENT_ACCOUNT_ID = 'curAccountId';

// app跳转到应用商店下载地址
export const WUKONG_IOS_DOWNLOAD_URL = 'https://apps.apple.com/be/app/wukong-class-learning-online/id1574837622';
export const WUKONG_ANDRIOD_DOWNLOAD_URL =
  'https://play.google.com/store/apps/details?id=com.wukongacademy.studentportal';
export const WUKONG_ANDRIOD_DOWNLOAD_APK = 'https://cdnwukong.com/public/app/WukongClass_latest_release.apk';

// IOS的universal link页面
export const WUKONG_IOS_CALL_APP_PATH = 'https://www.wukongsch.com/app/download/';
