import useUser from '@/store/useUser';
import { traceEvent } from '@wk/wk-gatherer';

const useTraceEventWithAccount = () => {
  const { curStudent, account } = useUser();

  const traceEventWithAccount = (rest: { [key: string]: any }) => {
    let data: any = {
      account_id: account?.uuid,
      Code: curStudent?.code,
    };
    const { needFamilyId, ...restData } = rest;
    if (needFamilyId) {
      data.familyId = account?.userFamilyId;
    }
    traceEvent({
      data: {
        ...data,
        ...(restData || {}),
      },
    });
  };

  return { traceEventWithAccount };
};

export default useTraceEventWithAccount;
