import React, { useEffect, useRef } from 'react';

import styles from './style.module.scss';
import classNames from 'classnames';
import { useWindowSize } from 'react-use';
import useIsPc from '@/utils/hooks/useIsPC';

const PageWrapper: React.FC = ({ children }) => {
  const isPc = useIsPc();
  const { height: windowHeight } = useWindowSize();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isPc || !containerRef.current) {
      return;
    }
    containerRef.current.style.minHeight = `${windowHeight}px`;
  }, [windowHeight]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={classNames(styles.content, 'bg-[#F7F7F7] lg:bg-transparent')}>{children}</div>
    </div>
  );
};

export default PageWrapper;
