import client from '@/api/axios';
import { ContentData, PageData } from '@/api/types';
import { AddressListRes, PackageSearch } from 'types/order';
import {
  CancelSubscriptionParams,
  Deductions,
  Featured,
  OrderListItem,
  SKU,
  SubscriptionItem,
  SubscriptionPriceSearch,
} from './types/order';
import { COMMON_API_HOST as COMMON_API } from '@/utils/env';
import { getAPIBaseUrl } from '@/utils/url';
import { IOrderStatus } from '@/pageComponents/OrderCenter/interface';

const COMMON_API_HOST = getAPIBaseUrl(COMMON_API);

/**
 *  订单列表
 * @returns
 */
export const queryOrderList = (pageNum: number, status: IOrderStatus) => {
  return client.post<{}, PageData<Array<OrderListItem>>>(`/api/student/order/my_order/${pageNum}`, {
    orderStatus: status || '',
    pageSize: 499,
  });
};

/**
 *  扣费明细
 * @returns
 */
export const getDeductions = (uuid: string) => {
  return client.get<{}, ContentData<Array<Deductions>>>(`/api/student/master/v2/deductions/${uuid}`);
};

/**
 * 查询国家列表
 */

export const getCountrys = async (): Promise<Array<AddressListRes>> => {
  const data = (await client.get(`${COMMON_API_HOST}/public/world_city?language=EN`)) as any;
  return data?.filter((item: any) => item.iso2 !== 'CN') || [];
};

/**
 * 根据ip地址获取当前地址信息
 */

export const getAddress = (): Promise<any> => {
  return client.get(`${COMMON_API_HOST}/public/ip/ipInfoAGGCms`);
};

/**
 * 我的订阅列表
 */

export const getMySubscription = (pageIndex: number, pageSize: number): Promise<PageData<SubscriptionItem>> => {
  return client.post(`/api/student/master/v2/my_subscription/${pageIndex}/${pageSize}`);
};

/**
 * 搜索代金券
 */
export const getVouchers = (pageNum: number, pageSize: number, data: any): Promise<any> => {
  return client.post(`/api/student/order/coupon/search/${pageNum}/${pageSize}`, data);
};
/**
 * 首页-精选课程
 */
export const getPackages = (): Promise<ContentData<Featured>> => {
  return client.post(`/api/student/master/v2/course/featured`);
};
/**
 * 3.0订阅详情
 * @returns
 */
export const getFeaturedDetail = (): Promise<ContentData<Featured>> => {
  return client.get(`/api/student/master/v2/course/featured/detail`);
};

/**
 * 精选课包分页搜索
 */

export const getCoursePackagesByPage = (pageNum: number, data: PackageSearch): Promise<any> => {
  return client.post(`/api/student/master/v2/course/CoursePackages/${pageNum}`, data);
};

/**
 * 取消订阅
 */

export const cancelSubscription = (data: CancelSubscriptionParams): Promise<any> => {
  return client.post(`/api/student/master/v2/apply_cancel_subscription`, data);
};

/**
 * 获取订阅详情
 */

export const getSubscriptionDetail = (uuid: string): Promise<any> => {
  return client.post(`/api/student/master/v2/subscription_detail/${uuid}`);
};

/**
 * 获取订单详情
 * @param uuid
 * @returns
 */
export const getOrderDetail = (uuid: string): Promise<any> => {
  return client.get(`/api/student/order/detail/${uuid}`);
};

/**
 * 3.8 获取订阅价格
 */

export const fetchActualPaymentAmount = (data: SubscriptionPriceSearch): Promise<SKU> => {
  return client.post(`/api/student/order/subscribe/sku/price`, data);
};
