import { ErrorData } from '../types';
import { Toast } from '@/components';

export const selfLeave = (data: ErrorData) => {
  switch (data.errorType) {
    default:
      Toast.error(data.errorMsg);
      break;
  }
};
