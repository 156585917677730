import SelfModal from '@/pageComponents/SelfSeries/SelfModal';
import { getSpaceT, SELFSERVICE_RESCHEDULING } from '../spaceT';
import { ErrorData } from '../types';
import { Toast } from '@/components';
import Router from 'next/router';
import { appJumpPage } from '@/utils/utils';
import { isAppEnv } from '@/utils/useGetDevice';

enum ErrorTypeEnum {
  OUT_OF_RANGE = 'OUT_OF_RANGE',
  NO_NEED_ADJUST = 'NO_NEED_ADJUST',
}

const spaceT = getSpaceT(SELFSERVICE_RESCHEDULING);

export const selfAdjust = (data: ErrorData) => {
  switch (data.errorType) {
    //课节不在可调范围
    case ErrorTypeEnum.NO_NEED_ADJUST: {
      SelfModal.confirm({
        content: spaceT('错误处理-没有可调的课-内容'),
        type: 'info',
        showCancelButton: false,
        confirmText: spaceT('错误处理-没有可调的课-按钮文案'),
        async onConfirm() {
          if (isAppEnv()) {
            appJumpPage(
              JSON.stringify({
                path: 'class',
                type: 'INTERNAL',
                customData: {},
              }),
            );
          } else {
            await Router.replace('/home/lesson');
          }
        },
      });
      break;
    }
    //没有可以调的课
    case ErrorTypeEnum.OUT_OF_RANGE: {
      SelfModal.confirm({
        content: spaceT('错误处理-课节不在可调范围-内容'),
        type: 'info',
        showCancelButton: false,
        confirmText: spaceT('错误处理-课节不在可调范围-按钮文案'),
        async onConfirm() {
          if (isAppEnv()) {
            appJumpPage(
              JSON.stringify({
                path: 'class',
                type: 'INTERNAL',
                customData: {},
              }),
            );
          } else {
            await Router.replace('/home/lesson');
          }
        },
      });
      break;
    }

    default:
      Toast.error(data.errorMsg);
      break;
  }
};
