import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { i18n } from 'next-i18next';
import { CSSTransition } from 'react-transition-group';
import Image from 'next/image';

import closeSvg from './icons/closeSvg.svg';
import inputClearSvg from './icons/inputClearSvg.svg';

import styles from './style.module.scss';
import classNames from 'classnames';
import SelfButton from '../SelfButton';

interface Props {
  title?: string;
  visible?: boolean;
  disableBodyScroll?: boolean;
  container?: Element;
  onCancel?: (fn: () => void, type: 'icon' | 'button') => void;
  onConfirm?: (value: any) => void;
  footer?: boolean;
  showCancelButton?: boolean;
  cancelText?: string;
  confirmText?: string;
  styleType?: 'confirm';
  type?: keyof Extra;
  prompt?: {
    required?: boolean;
    maxLength?: number;
    defaultValue?: string;
    value?: string;
    placeholder?: string;
    onChange?: (e: any) => void;
    onFocus?: () => void;
  };
  style?: CSSProperties;
  className?: string;
  confirmBtnStyle?: CSSProperties;
}

interface StaticMethodProps extends Omit<Props, 'visible'> {
  content?: React.ReactNode;
}

interface ConfirmProps extends StaticMethodProps {
  type: keyof Extra;
}

interface Extra {
  info: (e: StaticMethodProps) => void;
  prompt: (e: StaticMethodProps) => void;
  confirm: (e: ConfirmProps) => any;
}

const duration = 300;

const SelfModal: FC<Props> & Extra = (props) => {
  const {
    title,
    visible = false,
    disableBodyScroll = true,
    container,
    children,
    onCancel,
    onConfirm,
    cancelText,
    confirmText,
    footer = true,
    prompt,
    showCancelButton = true,
    styleType,
    style,
    className,
    confirmBtnStyle,
    type,
  } = props || {};
  const [innerVisible, setInnerVisibie] = useState(false);
  const [promptInnerValue, setPromptInnerValue] = useState(prompt?.defaultValue);
  const iptRef = useRef<HTMLInputElement>(null);
  const isFoucsRef = useRef(false);
  const [keyboardHeight, setKeyboardHeight] = useState<number | null>(null);

  useEffect(() => {
    const originalHeight = globalThis.innerHeight;

    globalThis.addEventListener('resize', () => {
      const newHeight = globalThis.innerHeight;
      const keyboardHeight = originalHeight - newHeight;

      // 解决webview里，ipt框被遮住的问题
      if (document.activeElement === iptRef.current && isFoucsRef.current) {
        setKeyboardHeight(keyboardHeight);
      } else {
        setKeyboardHeight(null);
      }
    });
  }, []);

  useEffect(() => {
    if (prompt?.value == null) return;

    setPromptInnerValue(prompt?.value);
  }, [prompt, prompt?.value]);

  const promptValue = useMemo(() => {
    return prompt?.value ?? promptInnerValue ?? '';
  }, [prompt?.value, promptInnerValue]);

  useEffect(() => {
    setInnerVisibie(visible);
  }, [visible]);

  useEffect(() => {
    if (innerVisible) {
      const overflowBuffer = document.body.style.overflow;
      if (disableBodyScroll) {
        document.body.style.overflow = 'hidden';
      }
      return (): void => {
        document.body.style.overflow = overflowBuffer;
      };
    }
    return () => {
      // nothing return
    };
  }, [disableBodyScroll, innerVisible]);

  const handleClose = useCallback(() => {
    if (visible === undefined) {
      setInnerVisibie(false);
    }
  }, [visible]);

  return globalThis.document?.body ? (
    ReactDOM.createPortal(
      <>
        {innerVisible && <div className={styles.mask} />}
        <CSSTransition
          in={innerVisible}
          timeout={duration}
          classNames={{
            enter: styles.modalEnter,
            enterActive: styles.modalEnterActive,
            exit: styles.modalExit,
            exitActive: styles.modalExitActive,
          }}
          unmountOnExit
        >
          <div
            className={classNames(styles.modal, styleType && styles[styleType], type && styles[type], className)}
            style={{ marginTop: keyboardHeight === null ? undefined : -keyboardHeight, ...style }}
          >
            <div className={styles.closeIcon} onClick={() => onCancel?.(handleClose, 'icon')}>
              <Image src={closeSvg} alt="close" />
            </div>
            {title && <div className={styles.title}>{title}</div>}
            <div className={styles.content}>{children}</div>
            {prompt && (
              <div className={styles.promptInputBox}>
                <input
                  className={styles.promptInput}
                  {...(prompt || {})}
                  placeholder={prompt?.placeholder || i18n?.t('占位符请输入')}
                  ref={iptRef}
                  value={promptValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPromptInnerValue(value);
                    prompt?.onChange?.(value);
                  }}
                  onFocus={() => {
                    isFoucsRef.current = true;
                    prompt?.onFocus?.();
                  }}
                  onBlur={() => {
                    isFoucsRef.current = false;
                  }}
                />
                {!!promptValue?.length && (
                  <div
                    className={styles.inputClearIcon}
                    onClick={() => {
                      setPromptInnerValue('');
                      prompt?.onChange?.('');
                    }}
                  >
                    <Image src={inputClearSvg} alt="" />
                  </div>
                )}
              </div>
            )}

            {footer && (
              <div
                className={classNames(styles.footer, !showCancelButton && styles.singleButton)}
                style={{
                  placeItems: showCancelButton ? 'space-between' : 'center',
                }}
              >
                {showCancelButton && (
                  <SelfButton
                    className={classNames([styles.cancelButton])}
                    onClick={() => onCancel?.(handleClose, 'button')}
                  >
                    {cancelText ?? i18n?.t('取消')}
                  </SelfButton>
                )}
                <SelfButton
                  type="primary"
                  className={classNames([
                    // styles.button,
                    styles.confirmButton,
                    // { [styles.disabled]: !promptValue && prompt?.required },
                  ])}
                  style={confirmBtnStyle}
                  disabled={!promptValue && prompt?.required}
                  onClick={() => {
                    handleClose();
                    onConfirm?.(promptValue);
                  }}
                >
                  {confirmText ?? i18n?.t('确认')}
                </SelfButton>
              </div>
            )}
          </div>
        </CSSTransition>
      </>,
      container ?? globalThis.document.body,
    )
  ) : (
    <></>
  );
};

SelfModal.confirm = (props: ConfirmProps) => {
  const div = document.createElement('div');
  const container = props?.container ?? globalThis.document.body;
  container?.appendChild(div);

  const params: Props = {
    ...props,
    visible: true,
    onCancel: (fn: () => void, type: 'icon' | 'button') => {
      fn();
      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(div);
        document.body.removeChild(div);
      }, duration);

      props?.onCancel?.(fn, type);
    },
    onConfirm: (value) => {
      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(div);
        document.body.removeChild(div);
      }, duration);

      props?.onConfirm?.(value);
    },
  };

  if (props.type === 'prompt') {
    params.prompt = {
      ...(props.prompt || {}),
    };
  }

  ReactDOM.render(<SelfModal {...params}>{props.content}</SelfModal>, div);
};

SelfModal.info = (props: StaticMethodProps) => {
  SelfModal.confirm({ type: 'info', ...props });
};

SelfModal.prompt = (props: StaticMethodProps) => {
  return SelfModal.confirm({ type: 'prompt', ...props });
};

export default SelfModal;
