import { IS_PROD } from '@/utils/env';
import {
  CURRENT_LANGUAGE,
  SIMP_OFFICIAL_WEBSITE_EDU_URL,
  SIMP_OFFICIAL_WEBSITE_URL,
  SIMP_OFFICIAL_WEBSITE_URL_TEST,
  STU_WEBSITE_URL,
} from '@/utils/constants';
import CacheStorage from '@/utils/cacheStorage';
import router from 'next/router';
import { removeQueryParam } from './url';

interface GoWebsiteOptions {
  selectedLang?: string;
}

/**
 * 退出登录后，从官网登录成功默认跳转首页
 * 在这里配置可以回到return_url 的路由
 */
const canBackRoutes = ['/home/bookClass', '/selectCourseEntry'];

const currentUrlIncludeBackRoutes = () => {
  for (let i = 0; i < canBackRoutes.length; i++) {
    const backRoute = canBackRoutes[i];
    if (window.location.href.includes(backRoute)) {
      return true;
    }
  }

  return false;
};

// 跳转到首页
const canBackHomeRoutes = ['home/close-account', 'home/cashier-desk'];

const isGoHome = () => {
  return canBackHomeRoutes.find((value: any) => window.location.href.includes(value));
};

const openUrl = (url: string) => {
  window.location.href = url;
};

export const goWebSite = (path: string | null = null, options?: GoWebsiteOptions) => {
  const lang = options?.selectedLang || (CacheStorage.getItem(CURRENT_LANGUAGE) === 'zh' ? 'zh' : '');
  // 线上环境针对国内用户，格外提供一个 www-intl.wukongedu.net 的官网域名用于登录，然后跳转到 student.wukongedu.net 这个域名
  let prodWebsiteUrl =
    window.location.hostname.includes('wukongedu.net') || window.location.hostname.includes('vercel.app')
      ? `${SIMP_OFFICIAL_WEBSITE_EDU_URL}/${lang}`
      : `${SIMP_OFFICIAL_WEBSITE_URL}/${lang}`;
  let officialWebsiteUrlTest = `${SIMP_OFFICIAL_WEBSITE_URL_TEST}/${lang}`;

  let finalHref = window.location.href;
  if (window.location.hostname.includes('vercel.app')) {
    finalHref = IS_PROD ? `${STU_WEBSITE_URL}/${lang}` : `${SIMP_OFFICIAL_WEBSITE_URL_TEST}/${lang}`;
  }

  const hostName = IS_PROD ? prodWebsiteUrl : officialWebsiteUrlTest;

  if (path) {
    openUrl(`${hostName}/${path}`);
    return;
  }

  /* 如果是注销账号的页面，注销成功后再登录后回到首页 */
  if (isGoHome()) {
    openUrl(`${hostName}/student-center-login`);
    return;
  }

  /* 如果能回到return_url, return_url为当前页面的url */
  if (currentUrlIncludeBackRoutes()) {
    const { asPath } = router;
    const url = removeQueryParam(`${window.location.origin}/${lang}${asPath}`, 'from');
    openUrl(`${hostName}/student-center-login?return_url=${encodeURIComponent(url)}`);
    return;
  }

  const url = removeQueryParam(finalHref, 'from');
  openUrl(`${hostName}/student-center-login?return_url=${encodeURIComponent(url)}`);
};
