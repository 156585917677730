import { AxiosError } from 'axios';
import { ErrorBusiness, ErrorData } from './types';
import { Toast } from '@/components';
import { selfAdjust, selfBooking, selfLeave } from './handlers';

const HANDLER_CODE = 409;

export const selfSeriesErrorHandler = async (err: AxiosError) => {
  const { response } = err;

  const { status } = response!;
  const data = response?.data as ErrorData;

  if (status === HANDLER_CODE) {
    if (data.errorType === 'OTHER') return Toast.error(data.errorMsg);

    const errHandlerMap: Record<ErrorBusiness, (e: ErrorData) => any> = {
      [ErrorBusiness['SELF-ADJUST']]: selfAdjust,
      [ErrorBusiness['SELF-BOOKING']]: selfBooking,
      [ErrorBusiness['SELF-LEAVE']]: selfLeave,
    };
    return errHandlerMap[data.business]?.(data);
  } else {
    const msg = data.message;
    Toast.error(msg ?? '未知错误');
  }
};
