import React, { MouseEvent, ReactNode } from 'react';
import cls from 'classnames';

export const ButtonV2Type = {
  GLOST: 'glost',
  DEFAULT: 'default',
  DISABLED: 'disabled',
};
export const ButtonType = [ButtonV2Type.GLOST, ButtonV2Type.DEFAULT, ButtonV2Type.DISABLED] as const;

export type IButtonType = (typeof ButtonType)[number];

export interface ButtonProps {
  children?: ReactNode;
  type?: IButtonType;
  onClick?: (e: MouseEvent<HTMLButtonElement | MouseEvent>) => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  visible?: boolean;
}

const ButtonV2 = (props: ButtonProps) => {
  const { children, onClick, disabled, type = ButtonV2Type.DEFAULT, className, visible = true } = props;
  return (
    <>
      {visible ? (
        <button
          className={cls(
            'box-border h-[40px] cursor-pointer rounded-[200px] border-[1.5px] text-[14px] font-semibold lg:h-[41px] lg:w-[120px]',
            type === ButtonV2Type.DEFAULT && 'border-none bg-[#CD292A] text-white hover:bg-[#DB0202]',
            type === ButtonV2Type.GLOST && ' border-solid border-[#f0f0f0] bg-white text-[#0A0A0A]',
            type === ButtonV2Type.DISABLED && 'border-none bg-[#f5d4d4] text-white',
            className,
          )}
          onClick={(e) => {
            if (disabled) return;
            onClick?.(e);
          }}
        >
          {children}
        </button>
      ) : null}
    </>
  );
};
export default ButtonV2;
