import classNames from 'classnames';
import { CSSProperties, FC, MouseEventHandler, useEffect, useState } from 'react';
import styles from './style.module.scss';

interface ButtonProps {
  onClick?: MouseEventHandler;
  className?: string;
  type?: 'default' | 'primary';
  style?: CSSProperties;
  children?: React.ReactNode;
  disabled?: boolean;
}

const SelfButton: FC<ButtonProps> = ({ children, onClick, className, type = 'default', style = {}, disabled }) => {
  const [innerDisabled, setInnerDisabled] = useState(false);
  useEffect(() => setInnerDisabled(disabled ?? false), [disabled]);

  return (
    <button
      onClick={onClick}
      className={classNames([styles.button, styles[type], className, { [styles.disabled]: innerDisabled }])}
      style={style}
      disabled={innerDisabled}
    >
      {children}
    </button>
  );
};
export default SelfButton;
