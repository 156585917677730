import SelfModal from '@/pageComponents/SelfSeries/SelfModal';
import { getSpaceT, SELF_BOOKING } from '../spaceT';
import { ErrorData } from '../types';
import { Toast } from '@/components';
import Router from 'next/router';
import { appJumpPage } from '@/utils/utils';
import { isAppEnv } from '@/utils/useGetDevice';
import { trace } from '@wk/wk-gatherer';

enum ErrorTypeEnum {
  ALREADY = 'ALREADY',
  // 时间冲突
  CONFLICT = 'CONFLICT',
  // 班级满员
  FORM_CLASS_FULL = 'FORM_CLASS_FULL',
}

const spaceT = getSpaceT(SELF_BOOKING);

export const selfBooking = (data: ErrorData) => {
  switch (data.errorType) {
    //课节不在可调范围
    case ErrorTypeEnum.ALREADY: {
      SelfModal.confirm({
        title: spaceT('错误弹窗-选课重复-标题'),
        content: spaceT('错误弹窗-选课重复-内容'),
        type: 'info',
        showCancelButton: false,
        confirmText: spaceT('错误弹窗-选课重复-按钮文案'),
        async onConfirm() {
          trace('C_SelectClass_DuplicateCoursesGo', {
            classType: Router.pathname.includes('private') ? '私教课' : '班课',
          });
          if (isAppEnv()) {
            appJumpPage(
              JSON.stringify({
                path: 'class',
                type: 'INTERNAL',
                customData: {},
              }),
            );
          } else {
            if (window.innerWidth < 1024) {
              await Router.replace('/home');
            } else {
              await Router.replace('/home/lesson');
            }
          }
        },
      });
      break;
    }
    //课节不在可调范围
    case ErrorTypeEnum.CONFLICT: {
      SelfModal.confirm({
        title: spaceT('错误弹窗-时间冲突-标题'),
        content: spaceT('错误弹窗-时间冲突-内容'),
        type: 'info',
        showCancelButton: false,
        confirmText: spaceT('错误弹窗-时间冲突-按钮文案'),
        async onConfirm() {
          trace('C_SelectClass_ScheduleConflictBack');
          Router.back();
        },
      });
      break;
    }
    //课节不在可调范围
    case ErrorTypeEnum.FORM_CLASS_FULL: {
      SelfModal.confirm({
        title: spaceT('错误弹窗-班级满员-标题'),
        content: spaceT('错误弹窗-班级满员-内容'),
        type: 'info',
        showCancelButton: false,
        confirmText: spaceT('错误弹窗-班级满员-按钮文案'),
        async onConfirm() {
          Router.back();
        },
      });
      break;
    }

    default:
      Toast.error(data.errorMsg);
      break;
  }
};
